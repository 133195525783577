import styled from "styled-components";

const colores = {
  borde: "#0076CF",
  error: "#F81934",
  exito: "#0076CF",
};

export const LabelSlider = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 40px;
  left: 200px;
  top: 0px;

  /* Extras/White */

  background: #ffffff;
  border-radius: 20px;
`;

export const BoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.padding || "80px"};
  gap: 32px;

  position: absolute;
  width: 1016px;
  height: 480px;
  left: calc(50% - 1016px / 2);
  top: calc(50% - 480px / 2 - 0px);

  /* Primarios/Sky blue 2 */

  background: #e3f5fd;
  box-shadow: -8px 8px 25px rgba(0, 76, 151, 0.2);
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
  z-index: 3;
  @media (max-width: 800px) {
    width: 363px;
    height: 540px;
    gap: 1rem;
  }
`;
export const BoxTitle = styled.div`
  width: ${(props) => props.width || "856px"};
  height: 49px;

  /* Header 2 */

  font-family: "eliotpro";
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */

  text-align: center;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  @media (max-width: 800px) {
    font-size: 30px;
    height: auto;
  }
`;

export const BoxDescription = styled.h2`
  width: ${(props) => props.width || "856px"};
  height: 87px;

  /* Header 6 */

  font-family: "eliotpro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  /* Inside auto layout */

  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
  @media (max-width: 800px) {
    font-size: 20px;
    height: auto;
  }
`;

export const BoxModal = styled.div`
  display: ${(props) => props.dis || "flex"};
  flex-wrap: wrap;
  align-items: center;

  gap: 13px;

  position: absolute;
  width: ${(props) => props.w || "370px"};
  height: ${(props) => props.h || "433px"};
  left: calc(50% - 370px / 2);
  top: calc(50% - 433px / 2 - 0px);

  background: #ffffff;
  box-shadow: -8px 8px 25px rgba(0, 76, 151, 0.2);
  border-radius: 16px;
  flex: none;
  order: 3;
  flex-grow: 0;
  z-index: 3;
`;

export const ModalTitle = styled.div`
  font-family: "eliotpro";
  width: 100%;
  max-width: ${(props) => props.maxw || " "};
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const CardOneSubtitle = styled.h3`
  width: 100%;
  height: 27px;
  margin-bottom: 7.5rem;
  /* Body text bold */
  text-align: left;
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Extras/White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  @media (max-width: 800px) {
    border: ${(props) => props.b800 || " "};
    margin-bottom: ${(props) => props.mbM || " "};
    font-family: "eliotprolight";
    font-size: ${(props) => props.fsM || " "};
    font-weight: ${(props) => props.fwM || " "};
    width: ${(props) => props.wM || ""};
  }
`;

export const ContainerSection = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fdir || "column"};
  width: ${(props) => props.w || "100%"};
  height: ${(props) => props.h || " "};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};
  align-items: center;
  /* justify-content:center ; */
  background: ${(props) => props.bgck || ""};
  opacity: ${(props) => props.op || " "};
  @media (max-width: 800px) {
    height: ${(props) => props.h800 || "auto "};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};
  }
  @media (max-width: 700px) {
    min-height: ${(props) => props.minh700 || "  "};
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.minh500 || "  "};
  }
`;

export const ContainerItems = styled.div`
  display: ${(props) => props.dis || " flex "};
  position: ${(props) => props.pos || ""};
  flex-direction: ${(props) => props.fdir || " "};
  flex-wrap: ${(props) => props.fw || " "};
  width: ${(props) => props.w || "100%"};
  height: ${(props) => props.h || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};
  margin-left: ${(props) => props.left || " "};
  background: ${(props) => props.bgck || ""};
  background-color: ${(props) => props.bgcolor || ""};
  font-familt: ${(props) => props.fontFamily || ""};
  opacity: ${(props) => props.op || " "};
  align-items: ${(props) => props.ai || "center"};
  align-content: ${(props) => props.alc || " "};
  justify-content: ${(props) => props.jc || "center"};
  max-width: ${(props) => props.mw || "81rem"};
  border: ${(props) => props.b || " "};
  border-radius: ${(props) => props.br || " "};
  gap: ${(props) => props.gap || " "};
  @media (max-width: 1300px) {
    padding: ${(props) => props.pad1300 || " "};
  }
  @media (max-width: 950px) {
    flex-direction: ${(props) => props.fdM900 || " "};
  }
  @media (max-width: 800px) {
    margin: ${(props) => props.mM || " "};
    padding: ${(props) => props.pM || " "};
    max-width: ${(props) => props.mwM || ""};
    width: ${(props) => props.w800 || ""};
    height: ${(props) => props.hM || ""};
    flex-direction: ${(props) => props.fdM || " "};
    height: ${(props) => props.hM || ""};
    gap: ${(props) => props.gapM || " "};
    border: ${(props) => props.b800 || " "};
    margin-top: ${(props) => props.mtM || " "};
    margin-bottom: ${(props) => props.mbM || " "};
    margin-left: ${(props) => props.mlM || " "};
  }

  @media (max-width: 800px) {
    width: ${(props) => props.w800 || ""};
    /* font-size:30px */
  }
`;

export const ContainerItemsAnim = styled.div`
  display: ${(props) => props.dis || " flex "};
  position: ${(props) => props.pos || ""};
  flex-direction: ${(props) => props.fdir || " "};
  flex-wrap: ${(props) => props.fw || " "};
  width: ${(props) => props.w || "500px"};
  height: ${(props) => props.h || "370px"};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};
  margin-left: ${(props) => props.left || " "};
  background: ${(props) => props.bgck || ""};
  opacity: ${(props) => props.op || " "};
  align-items: ${(props) => props.ai || "center"};
  align-content: ${(props) => props.alc || " "};
  justify-content: ${(props) => props.jc || "center"};
  max-width: ${(props) => props.mw || "81rem"};
  border: ${(props) => props.b || " "};
  border-radius: ${(props) => props.br || " "};
  gap: ${(props) => props.gap || " "};
  @media (max-width: 1300px) {
    padding: ${(props) => props.pad1300 || " "};
  }
  @media (max-width: 800px) {
    margin: ${(props) => props.mM || " "};
    padding: ${(props) => props.pM || " "};
    max-width: ${(props) => props.mwM || ""};
    width: ${(props) => props.wM || "363px"};
    height: ${(props) => props.hM || "268px"};
    flex-direction: ${(props) => props.disMob || " "};
    height: ${(props) => props.hM || ""};
    gap: ${(props) => props.gapM || " "};
    border: ${(props) => props.b800 || " "};
    margin-top: ${(props) => props.mtM || " "};
    margin-bottom: ${(props) => props.mbM || " "};
    margin-left: ${(props) => props.mlM || " "};
  }

  @media (max-width: 800px) {
    flex-direction: ${(props) => props.fdM || " "};
    width: ${(props) => props.w800 || ""};
    /* font-size:30px */
  }
`;

export const ItemWrapper = styled.div`
  display: ${(props) => props.dis || " flex "};
  width: ${(props) => props.w || "100%"};
  max-width: ${(props) => props.maxWidth || " "};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.mar || " "};
  flex-wrap: ${(props) => props.wrap || " "};
  border: ${(props) => props.b || " "};
  border-radius: ${(props) => props.br || " "};
  height: ${(props) => props.height || "100%"};
  overflow: ${(props) => props.overflow || " "};
  min-height: ${(props) => props.minh || " "};
  background-color: ${(props) => props.bgcolor || " "};
  @media (min-width: 1450px) {
    display: ${(props) => props.dis900 || " flex "};
    padding: ${(props) => props.pad1000 || " "};
    min-height: ${(props) => props.minh1400p || " "};
  }

  @media (max-width: 1250px) {
    padding: ${(props) => props.pad1250 || " "};
  }
  @media (max-width: 950px) {
    width: ${(props) => props.w800 || "100%"};
    display: ${(props) => props.dis900 || " flex "};
    padding: ${(props) => props.pad1000 || " "};
    max-height: ${(props) => props.maxH900 || " "};
    justify-content: ${(props) => props.jc900 || " "};
    overflow: hidden;
  }

  @media (max-width: 800px) {
    display: ${(props) => props.dis800 || " "};
    width: ${(props) => props.w800 || "100%"};
    padding: ${(props) => props.pad800 || "0px"};
    min-height: ${(props) => props.minHeightM || "100%"};
    max-height: ${(props) => props.maxH800 || " "};
    height: ${(props) => props.heightM || "100%"};
  }
  @media (max-width: 800px) {
    padding: ${(props) => props.pad500 || " "};
  }
`;

export const ItemWrapperaForeSearchSection = styled.div`
  display: ${(props) => props.dis || " flex "};
  width: ${(props) => props.w || "100%"};
  max-width: ${(props) => props.maxWidth || " "};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.mar || " "};
  flex-wrap: ${(props) => props.wrap || " "};
  border: ${(props) => props.b || " "};
  border-radius: ${(props) => props.br || " "};
  height: ${(props) => props.height || "100%"};
  overflow: ${(props) => props.overflow || " "};
  min-height: ${(props) => props.minh || " "};
  background-color: ${(props) => props.bgcolor || " "};
  background: url("/rocket/afores/static/assets/images/MiddleImage.png")
    no-repeat center center;

  background-size: cover;
  background-position: right;
  @media (min-width: 1450px) {
    display: ${(props) => props.dis900 || " flex "};
    padding: ${(props) => props.pad1000 || " "};
    min-height: ${(props) => props.minh1400p || " "};
  }

  @media (max-width: 1250px) {
    padding: ${(props) => props.pad1250 || " "};
  }
  @media (max-width: 950px) {
    width: ${(props) => props.w800 || "100%"};
    display: ${(props) => props.dis900 || " flex "};
    padding: ${(props) => props.pad1000 || " "};
    max-height: ${(props) => props.maxH900 || " "};
    justify-content: ${(props) => props.jc900 || " "};
    overflow: hidden;
  }

  @media (max-width: 800px) {
    display: ${(props) => props.dis800 || " "};
    width: ${(props) => props.w800 || "100%"};
    padding: ${(props) => props.pad800 || "0px"};
    min-height: ${(props) => props.minHeightM || "100%"};
    max-height: ${(props) => props.maxH800 || " "};
    height: ${(props) => props.heightM || "100%"};
  }
  @media (max-width: 800px) {
    padding: ${(props) => props.pad500 || " "};
  }

  @media (max-width: 768px) {
    background: url("/rocket/afores/static/assets/images/womenMobile.png")
      no-repeat center center;

    background-size: cover;
    background-position: center;
    background-position-y: top;
  }
`;

export const ItemWrapperaForeSearchSectionTwo = styled.div`
  display: ${(props) => props.dis || " flex "};
  width: ${(props) => props.w || "100%"};
  max-width: ${(props) => props.maxWidth || " "};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.mar || " "};
  flex-wrap: ${(props) => props.wrap || " "};
  border: ${(props) => props.b || " "};
  border-radius: ${(props) => props.br || " "};
  height: ${(props) => props.height || "100%"};
  overflow: ${(props) => props.overflow || " "};
  min-height: ${(props) => props.minh || " "};
  background-color: ${(props) => props.bgcolor || " "};
  background: url("../assets/images/MiddleImage.png") no-repeat center center;

  background-size: cover;
  background-position: right;
  @media (min-width: 1450px) {
    display: ${(props) => props.dis900 || " flex "};
    padding: ${(props) => props.pad1000 || " "};
    min-height: ${(props) => props.minh1400p || " "};
  }

  @media (max-width: 1250px) {
    padding: ${(props) => props.pad1250 || " "};
  }
  @media (max-width: 950px) {
    width: ${(props) => props.w800 || "100%"};
    display: ${(props) => props.dis900 || " flex "};
    padding: ${(props) => props.pad1000 || " "};
    max-height: ${(props) => props.maxH900 || " "};
    justify-content: ${(props) => props.jc900 || " "};
    overflow: hidden;
  }

  @media (max-width: 800px) {
    display: ${(props) => props.dis800 || " "};
    width: ${(props) => props.w800 || "100%"};
    padding: ${(props) => props.pad800 || "0px"};
    min-height: ${(props) => props.minHeightM || "100%"};
    max-height: ${(props) => props.maxH800 || " "};
    height: ${(props) => props.heightM || "100%"};
  }
  @media (max-width: 800px) {
    padding: ${(props) => props.pad500 || " "};
  }

  @media (max-width: 768px) {
    display: none;
    background: url("../assets/images/womenMobile.png") no-repeat center center;

    background-size: cover;
    background-position: center;
    background-position-y: top;
  }
`;

export const ItemWrapperDownloadSection = styled.div`
  display: ${(props) => props.dis || " flex "};
  width: ${(props) => props.w || "100%"};
  max-width: ${(props) => props.maxWidth || " "};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.mar || " "};
  flex-wrap: ${(props) => props.wrap || " "};
  border: ${(props) => props.b || " "};
  border-radius: ${(props) => props.br || " "};
  height: ${(props) => props.height || "100%"};
  overflow: ${(props) => props.overflow || " "};
  min-height: ${(props) => props.minh || " "};
  background-color: ${(props) => props.bgcolor || " "};
  background: url("/rocket/afores/static/assets/images/aforeApp.png") no-repeat
    center center;
  background-size: cover;
  @media (min-width: 1450px) {
    display: ${(props) => props.dis900 || " flex "};
    padding: ${(props) => props.pad1000 || " "};
    min-height: ${(props) => props.minh1400p || " "};
  }

  @media (max-width: 1250px) {
    padding: ${(props) => props.pad1250 || " "};
  }
  @media (max-width: 950px) {
    width: ${(props) => props.w800 || "100%"};
    display: ${(props) => props.dis900 || " flex "};
    padding: ${(props) => props.pad1000 || " "};
    max-height: ${(props) => props.maxH900 || " "};
    justify-content: ${(props) => props.jc900 || " "};
    overflow: hidden;
  }

  @media (max-width: 800px) {
    display: ${(props) => props.dis800 || " "};
    width: ${(props) => props.w800 || "100%"};
    padding: ${(props) => props.pad800 || "0px"};
    min-height: ${(props) => props.minHeightM || "100%"};
    max-height: ${(props) => props.maxH800 || " "};
    height: ${(props) => props.heightM || "100%"};
  }
  @media (max-width: 800px) {
    padding: ${(props) => props.pad500 || " "};
  }
`;

export const ItemWrapperDownloadSectionV2 = styled.div`
  display: ${(props) => props.dis || " flex "};
  width: ${(props) => props.w || "100%"};
  max-width: ${(props) => props.maxWidth || " "};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.mar || " "};
  flex-wrap: ${(props) => props.wrap || " "};
  border: ${(props) => props.b || " "};
  border-radius: ${(props) => props.br || " "};
  height: ${(props) => props.height || "100%"};
  overflow: ${(props) => props.overflow || " "};
  min-height: ${(props) => props.minh || " "};
  background-color: ${(props) => props.bgcolor || " "};
  background: url("/static/assets/images/aforeApp.jpg") no-repeat center center;
  background-size: cover;
  @media (min-width: 1450px) {
    display: ${(props) => props.dis900 || " flex "};
    padding: ${(props) => props.pad1000 || " "};
    min-height: ${(props) => props.minh1400p || " "};
  }

  @media (max-width: 1250px) {
    padding: ${(props) => props.pad1250 || " "};
  }
  @media (max-width: 950px) {
    width: ${(props) => props.w800 || "100%"};
    display: ${(props) => props.dis900 || " flex "};
    padding: ${(props) => props.pad1000 || " "};
    max-height: ${(props) => props.maxH900 || " "};
    justify-content: ${(props) => props.jc900 || " "};
    overflow: hidden;
  }

  @media (max-width: 800px) {
    display: ${(props) => props.dis800 || " "};
    width: ${(props) => props.w800 || "100%"};
    padding: ${(props) => props.pad800 || "0px"};
    min-height: ${(props) => props.minHeightM || "100%"};
    max-height: ${(props) => props.maxH800 || " "};
    height: ${(props) => props.heightM || "100%"};
    display: ${(props) => props.DMob || " "};
  }
  @media (max-width: 800px) {
    padding: ${(props) => props.pad500 || " "};
  }
`;

export const ItemWrapperMob = styled.div`
  display: ${(props) => props.dis || " flex "};
  width: ${(props) => props.w || "100%"};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};

  @media (max-width: 800px) {
    display: ${(props) => props.dis800 || " flex "};
  }
`;

export const ItemSection = styled.div`
  display: ${(props) => props.dis || " flex "};
  flex-direction: ${(props) => props.flexD || "column"};
  flex-wrap: ${(props) => props.wrap || " "};
  width: ${(props) => props.w || " "};
  max-width: ${(props) => props.mw || " "};
  height: ${(props) => props.h || " "};
  margin-left: ${(props) => props.left || " "};
  background: ${(props) => props.bgck || ""};
  opacity: ${(props) => props.op || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};
  justify-content: ${(props) => props.jc || " "};

  @media (max-width: 950px) {
    padding: ${(props) => props.pad900 || " "};
    display: ${(props) => props.dis900 || "flex"};
    margin: ${(props) => props.m900 || " "};
    width: ${(props) => props.w900 || "100%"};
  }

  @media (max-width: 800px) {
    padding: ${(props) => props.padM || " "};
    display: ${(props) => props.disMob || "flex"};
    margin: ${(props) => props.mMob || " "};
    width: ${(props) => props.w800 || "100%"};
  }

  @media (max-width: 425px) {
    display: ${(props) => props.disMob || "flex"};
    margin: ${(props) => props.m425Mob || "  "};
    justify-content: center;
    width: ${(props) => props.w425 || ""};
  }

  @media (max-width: 390px) {
    display: ${(props) => props.disMob || "flex"};
    margin: ${(props) => props.m425Mob || "  "};
  }
  @media (max-width: 320px) {
    display: ${(props) => props.disMob || "flex"};
    margin: ${(props) => props.m320Mob || "  "};
    padding-left: 0;
    padding-right: 0;
  }
`;

export const MainTitle = styled.h1`
  font-family: "eliotpro";
  font-style: bold;
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  color: #ffffff;
  margin-bottom: 37px;

  @media (max-width: 800px) {
    max-width: 328px;
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 50px;
  }
  @media (max-width: 320px) {
    max-width: 290px;
    margin-bottom: 30px;
  }
`;

export const Title = styled.h1`
  font-family: ${(props) => props.fontFamily || "eliotpro"};
  font-style: normal;
  font-weight: ${(props) => props.fontw || "700"};
  max-width: ${(props) => props.maxWidth || " "};
  font-size: ${(props) => props.fontSize || " 26px"};
  line-height: 35px;
  color: #333333;
  margin-bottom: 37px;
  text-align: ${(props) => props.textAlign || "center"};
  @media (max-width: 800px) {
    max-width: ${(props) => props.maxWidth760 || "328px;"};
    height: 58px;
    font-size: ${(props) => props.fsize800 || "30px"};
    font-weight: ${(props) => props.fontw800 || " "};
    line-height: 33px;
    margin-bottom: ${(props) => props.mb800 || "50px"};
  }
  @media (max-width: 320px) {
    max-width: ${(props) => props.maxWidth || "290px;"};
    margin-bottom: 30px;
  }
`;

export const Label = styled.p`
  width: ${(props) => props.w || " "};
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: ${(props) => props.lh || "18px "};
  color: #333333;
  text-align: start;
  margin-top: ${(props) => props.mt || "15px "};
  margin-right: 5px;
  z-index: 0;
  position: relative;
`;
export const InfoSimulador = styled.p`
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin-top: 1rem;
  line-height: 29px;
  width: 100%;
`;

export const InfoSimuladorMoney = styled.p`
  /* Header 3 */

  font-family: "eliotpro";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;

  /* Primarios/True blue */

  color: #0091da;
`;

export const Labels = styled.label`
  width: ${(props) => props.w || " "};
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: ${(props) => props.lh || "18px "};
  color: #333333;
  text-align: start;
  margin-top: ${(props) => props.mt || "15px "};
  margin-right: 5px;
  z-index: 0;
  position: relative;
`;

export const Paragraph14 = styled.p`
  font-family: "eliotprobold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  margin-bottom: 37px;
  @media (max-width: 800px) {
    width: ${(props) => props.wMob || " "};
    height: ${(props) => props.hMob || " "};
    margin-bottom: ${(props) => props.mbMob || "35px"};
    font-weight: ${(props) => props.fwMob || "400 "};
  }
  @media (max-width: 320px) {
  }
`;

export const CustomParagraphP = styled.p`
  width: ${(props) => props.w || " "};
  font-family: ${(props) => props.ff || "eliotpro"};
  font-weight: ${(props) => props.fontw || "400"};
  font-size: ${(props) => props.fs || "22px"};
  text-align: ${(props) => props.tal || " "};
  max-width: ${(props) => props.maxWidth || " "};
  margin: ${(props) => props.m || " "};
  margin-bottom: ${(props) => props.marginBottom || " "};
  padding: ${(props) => props.pad || " "};
  line-height: ${(props) => props.lh || "32px"};
  height: ${(props) => props.h || ""};
  color: ${(props) => props.clr || "#FFFFFF"};
  @media (max-width: 800px) {
    width: ${(props) => props.wMob || " "};
    font-size: ${(props) => props.fsMob || ""};
    font-family: ${(props) => props.ffMob || ""};
    height: ${(props) => props.hMob || " "};
    text-align: ${(props) => props.talMob || ""};
    margin-bottom: ${(props) => props.mbMob || ""};
    font-weight: ${(props) => props.fwMob || " "};
  }
  @media (max-width: 780px) {
    width: ${(props) => props.wMob7 || " "};
    margin-left: ${(props) => props.ml7 || " "};
    margin-bottom: ${(props) => props.mbb7 || ""};
  }
  @media (max-width: 375px) {
    width: ${(props) => props.wMob375 || " "};
    margin-left: ${(props) => props.ml375 || " "};
    margin-bottom: ${(props) => props.mbb375 || ""};
  }
`;

export const CustomParagraph = styled.div`
  width: ${(props) => props.w || " "};
  font-family: ${(props) => props.ff || "eliotpro"};
  font-weight: ${(props) => props.fontw || "400"};
  font-size: ${(props) => props.fs || "22px"};
  text-align: ${(props) => props.tal || " "};
  max-width: ${(props) => props.maxWidth || " "};
  margin: ${(props) => props.m || " "};
  margin-bottom: ${(props) => props.marginBottom || " "};
  padding: ${(props) => props.pad || " "};
  line-height: ${(props) => props.lh || "32px"};
  height: ${(props) => props.h || ""};
  color: ${(props) => props.clr || "#FFFFFF"};
  display: ${(props) => props.displayDes || ""};
  @media (max-width: 800px) {
    width: ${(props) => props.wMob || " "};
    font-size: ${(props) => props.fsMob || ""};
    font-family: ${(props) => props.ffMob || ""};
    height: ${(props) => props.hMob || " "};
    text-align: ${(props) => props.talMob || ""};
    margin-bottom: ${(props) => props.mbMob || ""};
    font-weight: ${(props) => props.fwMob || " "};
  }
  @media (max-width: 780px) {
    width: ${(props) => props.wMob7 || " "};
    margin-left: ${(props) => props.ml7 || " "};
    margin-bottom: ${(props) => props.mbb7 || ""};
    display: ${(props) => props.displayMob || ""};
  }
  @media (max-width: 375px) {
    width: ${(props) => props.wMob375 || " "};
    margin-left: ${(props) => props.ml375 || " "};
    margin-bottom: ${(props) => props.mbb375 || ""};
  }
`;

export const CurstomParagraph = styled.div`
  font-family: ${(props) => props.ff || "eliotpro"};
  font-weight: ${(props) => props.fontw || "400"};
  font-size: ${(props) => props.fs || " "};
  line-height: 15px;
  color: ${(props) => props.clr || "#FFFFFF"};
`;

export const Paragraph24 = styled.p`
  max-width: ${(props) => props.mw || "450px"};
  height: ${(props) => props.h || " "};
  font-family: "eliotpro";
  font-style: normal;
  font-weight: ${(props) => props.fw || "400"};
  font-size: 24px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: ${(props) => props.mb || "7px"};
  @media (max-width: 800px) {
    width: ${(props) => props.wMob || " "};
    height: ${(props) => props.hMob || " "};
    font-size: ${(props) => props.fsMob || "18px"};
    margin-bottom: ${(props) => props.mbMob || "15px"};
    font-weight: ${(props) => props.fwMob || " "};
  }
  @media (max-width: 320px) {
    margin-bottom: ${(props) => props.mb320Mob || "15px "};
  }
`;
export const Paragraph30 = styled.p`
  margin-top: 20px;
  height: 31px;
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;

  @media (max-width: 800px) {
    width: ${(props) => props.wMob || " "};
    height: ${(props) => props.hMob || " "};
    font-size: ${(props) => props.fsMob || "30px"};
    margin-top: 7px;
    font-weight: ${(props) => props.fwMob || " "};
  }
  @media (max-width: 320px) {
    margin-bottom: ${(props) => props.mb320Mob || "2px "};
  }
`;

export const ItemRow = styled.div`
  display: ${(props) => props.dis || " flex "};
  flex-wrap: ${(props) => props.fw || " wrap "};
  flex-direction: ${(props) => props.fd || " "};
  width: ${(props) => props.w || "50%"};
  align-items: ${(props) => props.alignIt || " "};
  height: ${(props) => props.h || " "};
  min-height: ${(props) => props.mh || " "};
  align-content: ${(props) => props.ac || "  "};
  justify-content: ${(props) => props.jc || ""};
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.m || " "};
  font-family: ${(props) => props.ff || "eliotpro"};
  font-weight: ${(props) => props.fw || " "};
  text-align: ${(props) => props.txal || " "};
  cursor: ${(props) => props.cursor || " "};
  @media (max-width: 800px) {
    display: ${(props) => props.dis900 || " flex "};
    width: ${(props) => props.w900 || " "};
    padding: ${(props) => props.pad900 || "unset"};
    margin: ${(props) => props.m900 || " "};
    justify-content: ${(props) => props.jcM900 || "center"};
    flex-direction: ${(props) => props.fd900 || " "};
  }
  @media (max-width: 800px) {
    display: ${(props) => props.dis800 || " flex "};
    justify-content: ${(props) => props.jcM || "center"};
    flex-direction: ${(props) => props.fd800 || " "};
  }

  @media (max-width: 550px) {
    display: ${(props) => props.dis500 || " flex"};
    width: ${(props) => props.w500 || " "};
    padding: ${(props) => props.pad500 || "unset"};
    margin: ${(props) => props.m500 || " "};
  }
`;

export const ItemCol = styled.div`
  display: ${(props) => props.dis || " block "};
  flex-wrap: ${(props) => props.wrap || " wrap "};
  width: ${(props) => props.w || " "};
  max-width: ${(props) => props.mw || " "};
  min-height: ${(props) => props.minh || " "};
  align-items: center;
  align-content: ${(props) => props.ac900 || " center "};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.m || " 0 3px "};
  background-color: ${(props) => props.bgcolor || " "};
  font-family: ${(props) => props.ff || "eliotpro"};
  @media (max-width: 1500px) {
    width: ${(props) => props.w1500 || " "};
  }
  @media (max-width: 900px) {
    display: ${(props) => props.dis900 || " "};
    width: ${(props) => props.w900 || " "};
    padding: ${(props) => props.pad900 || "unset"};
    margin: ${(props) => props.m900 || " "};
  }
  @media (max-width: 800px) {
    display: ${(props) => props.dis800 || " "};
    width: ${(props) => props.w800 || " "};
    max-width: ${(props) => props.mw800 || " "};
    padding: ${(props) => props.pad800 || " "};
    margin: ${(props) => props.m800 || " "};
  }

  @media (max-width: 550px) {
    width: ${(props) => props.w550 || ""};
    min-height: ${(props) => props.minh550 || " "};
  }
  @media (max-width: 400px) {
    width: ${(props) => props.w400 || ""};
    margin-top: ${(props) => props.mt400 || ""};
  }
`;

export const ContainerCtaBox = styled.div`
  display: "flex";
  flex-direction: "column";
  background: ${(props) => props.bgck || "#ffffff"};
  width: 650px;
  height: 210px;
  padding: 0px 0px 11px 17px;

  @media (max-width: 800px) {
    display: ${(props) => props.dis800 || " "};
    width: ${(props) => props.w800 || "100%"};
    height: ${(props) => props.h800 || "100%"};
    padding: ${(props) => props.pad800 || ""};
    margin: ${(props) => props.m800 || " "};
    border-radius: 3px 0px 0px 0px;
    padding: 0px 0px 11px 17px;
  }
`;

export const ContactInfoBox = styled.div`
  display: ${(props) => props.dis || " flex "};
  flex-wrap: ${(props) => props.wrap || " nowrap "};
  backdrop-filter: ${(props) => props.bf || "  "};
  width: ${(props) => props.w || " "};
  min-width: ${(props) => props.mw || "327px"};
  min-height: ${(props) => props.minh || "102px "};
  align-items: center;
  align-content: ${(props) => props.ac900 || " center "};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.m || " 0 3px "};
  background-color: ${(props) => props.bgcolor || "#081c2de3"};
  background: ${(props) => props.bg || ""};
  border-radius: 25px;
  color: #fff;
  border: 1px solid #0076cf;
  font-family: ${(props) => props.ff || "eliotprobold"};
  font-weight: 700;
  font-size: 22px;
  cursor: ${(props) => props.cursor || " "};
  transform: ${(props) => props.trans || "translateY(50px)"};
  @media (max-width: 800px) {
    width: ${(props) => props.wM || ""};
    font-family: ${(props) => props.ffM || "eliotpro"};
    font-weight: 400;
    min-width: ${(props) => props.mwM || "327px"};
  }
`;

export const InnerInfoBox = styled.div`
  display: ${(props) => props.dis || " block "};
  flex-wrap: ${(props) => props.wrap || " wrap "};
  width: ${(props) => props.w || "100%"};
  align-items: center;
  align-content: ${(props) => props.ac900 || " center "};
  justify-content: ${(props) => props.jc || "center"};
  margin: ${(props) => props.m || "auto"};
  text-align: center;
`;

export const Button = styled.button`
  width: ${(props) => props.w || "181px"};

  height: ${(props) => props.h || "48px"};
  background: ${(props) => props.bgck || "#0061A0"};
  border-radius: 40px;
  color: ${(props) => props.clr || "#FFFFFF"};
  font-family: ${(props) => props.ff || "eliotpro"};
  font-style: normal;
  font-weight: ${(props) => props.fontw || "400"};
  font-size: ${(props) => props.fs || "16px"};
  line-height: 18px;
  text-align: center;
  box-shadow: none;
  margin-top: ${(props) => props.mt || ""};
  margin-bottom: ${(props) => props.marginBottom || ""};
  padding: ${(props) => props.pad || ""};
  margin: ${(props) => props.m || ""};
  border: ${(props) => props.bder || "none"};
  margin-left: ${(props) => props.ml || ""};
  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.HoverbackgroundColor || "#004887"};
    color: ${(props) => props.hColor || "#FFFFFF"};
  }
  @media (max-width: 950px) {
    margin: ${(props) => props.mMob900 || ""};
  }
  @media (max-width: 800px) {
    width: ${(props) => props.wMob || ""};

    height: ${(props) => props.hMob || " "};
    color: ${(props) => props.clrMob || ""};
    margin: ${(props) => props.mMob || ""};
    background: ${(props) => props.bgMob || ""};
    font-family: ${(props) => props.ffMob || ""};
    margin-bottom: ${(props) => props.mbMob || " "};
    align-self: ${(props) => props.als || " "};
    display: ${(props) => props.DisMob || " "};
  }
  @media (max-width: 335px) {
    width: ${(props) => props.wMob425 || ""};
  }
`;

export const Image = styled.img`
  max-width: ${(props) => props.maxwidth || "100%"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || " "};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.bottom || ""};
  margin-right: ${(props) => props.marginRight || ""};
  margin-left: ${(props) => props.marginLeft || ""};
  position: ${(props) => props.position || "initial"};
  left: ${(props) => props.left || ""};
  top: ${(props) => props.top || ""};
  cursor: ${(props) => props.cursor || " "};
  display: ${(props) => props.displayWeb || " "};
  border: ${(props) => props.border || " "};
  z-index: ${(props) => props.zindex || ""};
  transform: ${(props) => props.transform || ""};
  transform-origin: ${(props) => props.to || ""};
  max-height: ${(props) => props.mheight || " "};
  @media (max-width: 800px) {
    margin-bottom: ${(props) => props.bottomMObile || ""};
    width: ${(props) => props.mwidth || props.width || "100%"};
    left: ${(props) => props.mleft || ""};
    top: ${(props) => props.mtop || ""};
    margin-right: ${(props) => props.marginRightM || ""};
    margin-top: ${(props) => props.marginTopM || ""};
    margin-left: ${(props) => props.marginLeftM || ""};
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displayMobile || " "};
    height: ${(props) => props.heightMobile || " "};
  }
  @media (max-width: 460px) {
    width: ${(props) => props.mwidth || props.width || "100%"};
    height: ${(props) => props.heightMobile || " "};
    display: ${(props) => props.displayMobile || " "};
    margin-top: ${(props) => props.mtMob || " "};
    margin-bottom: ${(props) => props.mbMob || " "};
    margin-left: ${(props) => props.mlMob || " "};
  }
`;

export const Color = styled.div`
  width: ${(props) => props.w || " 10px"};
  height: ${(props) => props.h || " 10px"};
  align-items: center;
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.pad || " 10px 5px "};
  background-color: ${(props) => props.bgcolor || " "};
`;

export const TitleSimuladorAfore = styled.p`
  width: 100%;
  height: ${(props) => props.h || "98px"};
  margin-top: ${(props) => props.mt || " "};
  margin-bottom: ${(props) => props.mBottom || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.padding || ""};
  /* Header 2 */
  color: #333333;
  font-family: ${(props) => props.fontf || "eliotprolight"};
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  @media (max-width: 768px) {
    line-height: ${(props) => props.mLineHeight || "49px"};
    font-size: ${(props) => props.mFontSize || "40px"};
  }
`;

export const TitlePerfiladorAfore = styled.p`
  width: ${(props) => props.w || "100%"};
  height: ${(props) => props.h || " "};
  margin: ${(props) => props.m || " "};
  font-family: ${(props) => props.fontf || "eliotpro"};
  font-style: normal;
  font-weight: 300;
  font-size: ${(props) => props.fons || "40px"};
  line-height: 49px;
  text-align: ${(props) => props.textAlign || "center"};
  @media (max-width: 1268px) {
    width: ${(props) => props.wM1milup || "100%"};
    font-size: ${(props) => props.mFontSize || "40px"};
    line-height: ${(props) => props.mLineHeight || "49px"};
  }
  @media (max-width: 990px) {
    width: ${(props) => props.wM9680 || "100%"};
    font-size: ${(props) => props.mFontSize || "40px"};
    line-height: ${(props) => props.mLineHeight || "49px"};
  }
  @media (max-width: 768px) {
    width: ${(props) => props.wM || "100%"};
    font-size: ${(props) => props.mFontSize || "40px"};
    line-height: ${(props) => props.mLineHeight || "49px"};
    margin: ${(props) => props.m768 || ""};
  }
`;

export const CardAnimationTwo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.padding || "40px 32px"};
  gap: 24px;

  width: ${(props) => props.width || "508px"};
  height: ${(props) => props.height || "371px"};

  /* Extras/White */

  background: ${(props) => props.bgColor || "#ffffff"};
  box-shadow: ${(props) =>
    props.boxShadow || "-8px 8px 25px rgba(0, 76, 151, 0.2)"};
  border-radius: 16px;
`;

export const CardCarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 10px;
  margin-left: 80px;
  width: 285.33px;
  height: ${(props) => props.height || "174px"};
  background: Transparent;
  border: 1px solid #c5c6c5;
  box-shadow: -8px 8px 25px rgba(0, 76, 151, 0.2);
  border-radius: 16px;
  @media (max-width: 800px) {
    margin-left: 2%;
    margin-right: 2%;
    width: 90%;
    justify-content: ${(props) => props.jcM || ""};
    height: ${(props) => props.hM || ""};
    gap: ${(props) => props.gapM || ""};
    padding: 12px;
  }
`;

export const TextCarouselTwo = styled.p`
  width: ${(props) => props.w || "  "};
  height: 58px;
  font-family: "eliotprolight";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  margin: ${(props) => props.m || " 0px 0px 33px 60px "};
  padding: ${(props) => props.pad || " "};
  @media (max-width: 800px) {
    height: auto;
    font-size: ${(props) => props.fsM || " "};
    padding: ${(props) => props.padM || " "};
    margin-top: 25px;
    width: ${(props) => props.wM || "100%"};
    margin: ${(props) => props.mM || " "};
  }
`;
export const TextItem = styled.p`
  width: ${(props) => props.width || "237.33px"};
  color: ${(props) => props.c || " "};
  font-family: "eliotprolight";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-bottom: ${(props) => props.mBottom || " "};
  @media (max-width: 800px) {
    font-family: ${(props) => props.ffM || ""};
    line-height: ${(props) => props.lhM || ""};
    font-size: ${(props) => props.fsM || " "};
  }
`;

export const TextCantidad = styled.p`
  width: 175px;
  font-family: "eliotproheavy";
  font-weight: 900;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: ${(props) => props.c || "#004c97 "};
  @media (max-width: 800px) {
    font-size: ${(props) => props.fsM || " "};
  }
`;

export const TextCarousel = styled.p`
  width: 1016px;
  font-family: "eliotprolight";
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  color: #ffffff;
  margin: ${(props) => props.m || " "};
  @media (max-width: 800px) {
    width: 363px;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    margin-bottom: ${(props) => props.mbM || "3.75rem"};
  }
`;

export const TextSmall = styled.p`
  width: ${(props) => props.width || "302px"};
  margin-top: ${(props) => props.mTop || "0px"};
  height: ${(props) => props.h || "100px"};
  font-family: "eliotprolight";
  font-style: normal;
  font-weight: 700;
  color: ${(props) => props.c || "black"};
  font-size: ${(props) => props.fz || "16px"};
  margin-right: ${(props) => props.mr || ""};
  line-height: 19px;
  text-align: ${(props) => props.ta || "center"};
  @media (max-width: 800px) {
    width: ${(props) => props.widthM || "45%"};
    height: 100%;
    font-size: ${(props) => props.fzM || "16px"};
    padding-top: ${(props) => props.ptM || "2rem"};
    font-family: "eliotpro";
    padding-bottom: 1rem;
    margin-top: 0px;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: left;
    margin-right: ${(props) => props.mMr || ""};
  }
`;

export const ContainerBoxCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${(props) => props.width || "474px"};
  margin-left: ${(props) => props.mLeft || ""};
  height: ${(props) => props.h || "144px"};
  padding-right: ${(props) => props.pr || " "};
  color: #0061a0;

  background: ${(props) => props.bgck || "#0076CF"};
  box-shadow: -8px 8px 25px rgba(0, 76, 151, 0.2);
  border-radius: 16px 0px 0px 0px;
  &:hover {
    cursor: pointer;
    background: ${(props) => props.bgck || "#005DB6"};
    color: #002e6d;
  }
  &:active {
    background: ${(props) => props.bgck || "#00439C"};
    color: #002e6d;
  }

  @media (max-width: 800px) {
    padding-right: ${(props) => props.prM || " "};
    width: ${(props) => props.widthM || "363px"};
    margin-left: 0rem;
    height: 174px;
  }
`;

export const ContainerBoxLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 211px;

  /* Primarios/Principal blue */

  background: ${(props) => props.Bck || " "};
  box-shadow: -8px 8px 25px rgba(0, 76, 151, 0.2);
  border-radius: 16px 0px 0px 0px;
  @media (max-width: 800px) {
    height: 174px;
    width: ${(props) => props.widthM || "10px"};
  }
  /* Inside auto layout */
`;

export const FormContainer = styled.div`
  display: flex;
  width: ${(props) => props.w || "508px"};
  flex-direction: column;
  height: ${(props) => props.h || "700px"};
  align-items: ${(props) => props.align_it || ""};
  justify-content: ${(props) => props.just_cont || ""};
  background: ${(props) => props.bg_form || "red"};
  gap: ${(props) => props.gap || ""};
  padding: ${(props) => props.pad || ""};
  @media (max-width: 800px) {
    width: ${(props) => props.wM || ""};
    margin: ${(props) => props.mM || " "};
  }
`;

export const FormLabel = styled.p`
  font-family: "eliotprolight";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: 0px;
  margin-top: 4px;
`;

export const Inp = styled.input`
  box-sizing: border-box;

  box-sizing: border-box;

  width: 450px;
  height: 48px;
  background: #ffffff;
  border-radius: 4px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

// export const Inpt = styled(TextField)`
//   width: ${(props) => props.w || " 100%"};
//   height: 48.92px;
//   font-weight: 400;
//   box-sizing: border-box;
//   font-size: 16px;
//   font-family: "eliotpro";
//   background: #ffffff;
//   padding: 8px 0px 18px 8px;
//   border: 1px solid blue;
//   border-radius: 4px;
//   color: #333333;
//   transform: matrix(1, 0, 0, 1, 0, 0);
//   margin-bottom: ${(props) => props.mbtn || " "};

//   &:focus {
//     border: 3px solid ${colores.borde};
//     outline: none;
//     box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
//   }

//   .MuiInput-underline:before {
//     border-bottom: none;
//   }
//   && .MuiInput-underline:hover:before {
//     border-bottom: none;
//   }
//   /* focused */
//   .MuiInput-underline:after {
//     border-bottom: none;
//   }
// `;

export const Title2 = styled.p`
  width: 100%;
  height: 48px;

  /* MOBILE/Title */

  font-family: "SF UI";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  @media (max-width: 800px) {
    width: 95%;
    margin-bottom: 33px;
  }
  @media (max-width: 375px) {
    width: 98%;
    margin-bottom: 33px;
  }
`;

export const Formulario = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.gtc || "1fr 1fr"};

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

export const SubSectionForm = styled.div``;

export const ContainerButton = styled.div`
  display: ${(props) => props.dis || "flex"};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;

  margin-top: ${(props) => props.mt || "30px"};
  margin-bottom: ${(props) => props.mb || "20px"};
  margin-left: ${(props) => props.ml || ""};
  width: 245px;
  height: 45px;
  background: #e8e8e8;
  border-radius: 24px;
  @media (max-width: 800px) {
    display: ${(props) => props.disM || "flex"};
    margin-left: 22%;
  }
  @media (max-width: 475px) {
    margin-left: 15%;
    margin-top: -20px;
    margin-bottom: 24px;
  }
  @media (max-width: 375px) {
    margin-left: 10%;
    margin-top: -20px;
    margin-bottom: 20px;
  }
`;

export const Btn = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-family: 'SF UI';
padding: 0px;
width: 102px;
height: 32px;
color:#002E6D;
box-shadow:${(props) => props.bs || " "}; 
border-radius:${(props) => props.br || " "}; 
background:${(props) => props.bg || " "};
flex: none;
cursor:pointer;
&:hover {
  cursor: pointer;

  color:#002E6D;
}
&:active {
 
  
  
  color:#002E6D;

`;

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fd || " "};
  margin-top: ${(props) => props.mt || " "};
  margin-bottom: ${(props) => props.mb || " "};
`;

export const ContainerIn = styled.div`
  display: flex;
  margin-left: ${(props) => props.ml || " "}; ;
`;

export const ContainerSelect = styled.div`
  @media (max-width: 800px) {
    margin-left: 25%;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  @media (max-width: 475px) {
    margin-left: 15%;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  @media (max-width: 375px) {
    margin-left: 15%;
    margin-top: 30px;
    margin-bottom: 50px;
  }
`;

export const ContainerRadioBtn = styled.div`
  margin-top: 25px;
  margin-bottom: 30px;

  @media (max-width: 800px) {
    margin-left: 25%;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  @media (max-width: 475px) {
    margin-left: 15%;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  @media (max-width: 375px) {
    margin-left: 15%;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;

export const LabelWcurp = styled.label`
  display: ${(props) => props.dis || " "};
  font-family: "SF UI";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

export const SimpleLink = styled.a`
  display: flex;
  text-decoration: ${(props) => props.textDec || "underline !important"};
  text-underline-offset: 4px;
  text-decoration-style: dashed !important;
  color: ${(props) => props.textColor || "#004C97 !important"};
  font-size: ${(props) => props.fontSize || " "};
  font-weight: ${(props) => props.fontWeight || " "};
  font-family: ${(props) => props.fontFamily || "eliotpro"};
`;

export const TextTen = styled.span`
  color: ${(props) => props.color || "#333333"};
  display: ${(props) => props.display || ""};
  font-family: ${(props) => props.fontFamily || "eliotpro"};
  font-size: ${(props) => props.fontSize || ""};
  font-style: ${(props) => props.fontStyle || "normal"};
  font-weight: ${(props) => props.fontWeight || "400"};
  text-align: ${(props) => props.textAlign || "left"};
  margin: ${(props) => props.margin || ""};
  margin-top: ${(props) => props.marginTop || ""};

  @media (max-width: 768px) {
    display: ${(props) => props.displayMob || " "};
    font-size: ${(props) => props.fontSizeMobile || ""};
    margin: ${(props) => props.marginMobile || ""};
  }
`;
