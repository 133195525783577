import React from "react";
import {
  ContainerGeneral,
  BoxContainer,
  BoxItem,
  TextOne,
  TextTwo,
  ImagenMobileCar,
  ImagenCar,
} from "./../Elements/ContainerSix.styled.js";
import ManCarro from "./../../../assets/img/CarroimgSectionSix.svg";
import ManCarroMob from "./../../../assets/img/CarroMobile.svg";
const ContainerSix = () => {
  return (
    <ContainerGeneral bgck="red" h="463px">
      <BoxContainer fdir="row">
        <BoxItem h="463px" w=" " m="0px 0px 0px 0px" disMob="none">
          <ImagenCar src={ManCarro} alt="carro" />
        </BoxItem>

        <BoxItem m="0px 0px 0px 0px" dis="none" disMob="flex">
          <ImagenMobileCar src={ManCarroMob} alt="carro" />
        </BoxItem>

        <BoxItem
          h="463px"
          bgck="#F0FBFE"
          w="52%"
          fdir="column"
          h800="344px"
          padMob="0px 3% 0px 3%"
          wMob="100%"
        >
          <TextOne>
            "Principal me ha ayudado a crear un plan de ahorro para mi retiro,
            ya puedo estar tranquilo de mi futuro"
          </TextOne>
          <TextTwo>Armando, 48 años.</TextTwo>
        </BoxItem>
      </BoxContainer>
    </ContainerGeneral>
  );
};

export default ContainerSix;
