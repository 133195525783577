import { TextTen } from "../../../assets/StylesGeneric.styled";
import curp from "./../../../assets/img/curp.svg";
import file from "./../../../assets/img/file.svg";
import download from "./../../../assets/img/download.svg";
import arrowDown from "./../../../assets/img/Arrow_down.svg";
import Accordion from "../../../Common/Components/Accordion";
import { scroller } from "react-scroll";

const StepsToDownload = () => {
  const steps = [
    { title: "Descarga la App", img: download },
    { title: "CURP", img: curp },
    { title: "Expediente de Identificación", img: file },
  ];
  return (
    <div className="row d-flex">
      {steps.map((step, idx) => {
        const { title, img } = step;
        return (
          <div key={idx} className="col-md-4 d-flex align-items-center">
            <img src={img} className="me-md-4 me-2 step-image" />
            <TextTen margin="0.5rem 0px" display="block" fontSize="18px">
              {title}
            </TextTen>
          </div>
        );
      })}
    </div>
  );
};

const DownloadBtn = () => {
  const scrollToElement = (element) => {
    scroller.scrollTo(element, {
      duration: 100,
      smooth: true,
      offset: 1,
    });
  };
  return (
    <>
      <TextTen
        marginMobile="0.5rem 0px"
        display="block"
        textAlign="center"
        fontSize="20px"
        fontWeight="700"
        color="#333333"
      >
        ¡Descárgala ahora!
      </TextTen>
      <img
        src={arrowDown}
        className="pointer mt-3"
        onClick={() => scrollToElement("downloadPrincipal")}
      />
    </>
  );
};

const ChangeToPrincipal = () => {
  return (
    <>
      <TextTen
        margin="3rem 0px"
        marginMobile="1.5rem 0px"
        display="block"
        textAlign="center"
        fontSize="30px"
        fontSizeMobile="22px"
        color="#333333"
        fontWeight="600"
      >
        ¿Qué necesito?
      </TextTen>
      <div className="row d-flex justify-content-center mb-md-0 mb-5 pb-md-0 pb-4">
        <div className="col-md-12 col-10">
          <StepsToDownload />
        </div>
      </div>
      <TextTen
        margin="2rem 0px 2.5rem 0px"
        display="block"
        textAlign="center"
        displayMob="none"
        fontSize="22px"
      >
        El proceso de traspaso se hace directamente en la App, descárgala y te
        guiaremos en cada momento. Estamos listos para acompañarte y ayudarte a
        planear tu futuro con nosotros.
      </TextTen>
      <DownloadBtn />
      <TextTen
        margin="3rem 0px 2rem 0px"
        marginMobile="2rem 0px"
        display="block"
        textAlign="center"
        fontSize="24px"
        fontSizeMobile="22px"
      >
        Conoce los pasos para realizar el traspaso.
        <TextTen
          margin="2rem 1px"
          textAlign="center"
          fontSize="20px"
          color="#004C97"
          fontWeight="700"
        >
          Ver más
        </TextTen>
      </TextTen>
    </>
  );
};

const ContainerFour = () => {
  return (
    <div className="text-center row d-flex justify-content-center pt-md-5 py-3">
      <div className="col-lg-8 col-11">
        <TextTen
          margin="0rem 0px 0.5rem 0px"
          display="block"
          textAlign="center"
          fontSize="40px"
          fontSizeMobile="30px"
        >
          ¡Realiza el traspaso a Principal® México!
        </TextTen>
        <div className="d-block d-md-none">
          <Accordion seeMoreElement={<ChangeToPrincipal />} />
        </div>
        <div className="d-none d-md-block">
          <ChangeToPrincipal />
        </div>
      </div>
    </div>
  );
};

export default ContainerFour;
