import React from "react";

import styled from "styled-components";
import ImgWhatsapp from "./../../assets/img/icono_Whatsapp.svg";
import X from "./../../assets/img/x.svg";

const Container = styled.div`
  position: fixed;
  bottom: 25px;
  right: 3%;
  text-align: end;
  font-size: 35px;
  z-index: 100;
`;
const BoxContainer = styled.div`
  display: ${(props) => props.dis || " "};
  flex-direction: row;
  align-items: center;
  padding: 13px 24px 0px 16px;

  width: 225px;
  height: 56px;
  background: #ffffff;
  border-radius: 6.17726px;
  box-shadow: -16px 7px 28px 0px rgba(0, 0, 0, 0.22);
  margin: 0px 0px 10px 0px;
`;

const Text = styled.p`
  width: 100%;
  height: 24px;
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #002855;
`;

const Containerx = styled.div`
  pointer: cursor;
  margin: 0px;
`;
const IconContainer = styled.div`
  margin: 0px;
`;
const Icon = styled.img`
  margin: 0px 0px 19px 13px;
`;

const WhatsappBtn = () => {
  const [open, setOpen] = React.useState(true);

  const CloseFuntion = () => {
    setOpen(false);
  };

  return (
    <Container>
      <BoxContainer dis={open === true ? "flex" : "none"}>
        <Text>¿Necesitas ayuda?</Text>

        <Containerx onClick={CloseFuntion}>
          <Icon src={X} />
        </Containerx>
      </BoxContainer>
      <IconContainer>
        <img src={ImgWhatsapp} alt=" ImgWhatsapp" />
      </IconContainer>
    </Container>
  );
};

export default WhatsappBtn;
