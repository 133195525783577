import React, { Fragment } from "react";
import ContianerOne from "./Components/ContianerOne";
import ContianerTwo from "./Components/ContainerTwo";
import ContainerThree from "./Components/ContainerThree";
import ContainerFour from "./Components/ContainerFour";
import ContianerFive from "./Components/ContainerFive";
import ContianerSix from "./Components/ContainerSix";
import WhatsappBtn from "../../Common/Components/WhatsappBtn";
import styled from "styled-components";
import { Element } from "react-scroll";
const Container = styled.div`
  position: abosulte;
  z-index: 0;
  overflow-x: hidden !important;
`;

function SectionOne(props) {
  return (
    <Container>
      <WhatsappBtn />
      <ContianerOne />

      <Element name="InfoRetiro">
        <ContianerTwo />
      </Element>
      <ContainerThree />
      <ContainerFour />
      <Element name="downloadPrincipal">
        <ContianerFive />
      </Element>
      <ContianerSix />
    </Container>
  );
}

export default SectionOne;
