import React from "react";
import {
  ContainerGeneral,
  BoxContainer,
  BoxItem,
  TextMain,
  TextSub,
  TextOne,
  TextTwo,
  VermasBtn,
} from "./../Elements/ContainerTwo.styled";
import Gif from "./../../../assets/gif/PrincipalContainerTwo.gif";
const ContainerTwo = () => {
  const [openText, setOpenText] = React.useState(false);
  return (
    <ContainerGeneral>
      <TextMain>¿Por qué tener un plan de retiro?</TextMain>
      <TextSub>
        Un plan de retiro te ayudará a tener el dinero necesario para poder
        vivir cómodamente después de tu jubilación.
      </TextSub>

      {/* Des */}
      <BoxContainer fdir="row" disMob="none">
        <BoxItem w="50%" h="400px" m="0px 0px 0px 0px">
          <img src={Gif} width="500px" />
        </BoxItem>

        <BoxItem w="50%" h="400px" m="90px 0px 0px 73px">
          <TextOne>
            Una Afore es la encargada de administrar tus fondos para el momento
            de tu retiro.
          </TextOne>

          <TextTwo>
            Desde tu primer trabajo se creará una cuenta única y personal
            llamada "Cuenta Individual" . <br /> <br /> El patrón, el gobierno y
            el trabajador aportan dinero a la Cuenta Individual.
          </TextTwo>
        </BoxItem>
      </BoxContainer>

      {/* Mobile */}
      <BoxContainer fdir="column" dis="none" disMob="flex">
        <VermasBtn onClick={() => setOpenText(true)}>Ver mas</VermasBtn>

        <BoxItem w="100%">
          <img src={Gif} width="100%" />
        </BoxItem>

        {openText == true ? (
          <BoxItem w="98%" m="40px 0px 0px 0px" padMob="0px 0px 30px 2%">
            <TextOne>
              Una Afore es la encargada de administrar tus fondos para el
              momento de tu retiro.
            </TextOne>

            <TextTwo>
              Desde tu primer trabajo se creará una cuenta única y personal
              llamada "Cuenta Individual" . <br /> <br /> El patrón, el gobierno
              y el trabajador aportan dinero a la Cuenta Individual.
            </TextTwo>
          </BoxItem>
        ) : null}
      </BoxContainer>
    </ContainerGeneral>
  );
};

export default ContainerTwo;
