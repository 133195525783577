import PrincipalFeatures from "./Others/PrincipalFeatures";
import { TextTen } from "../../../assets/StylesGeneric.styled";

const ContainerThree = () => {
  return (
    <div className="text-center row d-flex justify-content-center eliotpro py-md-5 py-3 mb-md-5">
      <div className="col-xl-8 col-lg-10 col-11">
        <TextTen
          margin="0rem 0px 0.5rem 0px"
          display="block"
          textAlign="center"
          fontSize="40px"
          fontSizeMobile="30px"
        >
          Con principal te ayudamos a planear tu retiro
        </TextTen>
        <PrincipalFeatures />
      </div>
    </div>
  );
};

export default ContainerThree;
