import styled from "styled-components";

export const ContainerGeneral = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fdir || " "};
  width: ${(props) => props.w || "100%"};
  height: ${(props) => props.h || " "};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || "0px "};
  background: ${(props) => props.bgck || "#fff"};
  opacity: ${(props) => props.op || " "};
  @media (max-width: 800px) {
    height: ${(props) => props.h800 || "auto "};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};
  }
  @media (max-width: 700px) {
    min-height: ${(props) => props.minh700 || "  "};
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.minh500 || "  "};
  }
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fdir || " "};
  width: ${(props) => props.w || "100% "};
  height: ${(props) => props.h || " "};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};

  background: ${(props) => props.bgck || "#fff"};
  opacity: ${(props) => props.op || " "};
  @media (max-width: 800px) {
    height: ${(props) => props.h800 || "auto "};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};
  }
  @media (max-width: 700px) {
    min-height: ${(props) => props.minh700 || "  "};
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.minh500 || "  "};
  }
`;

export const BoxItem = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fdir || " "};
  width: ${(props) => props.w || "50% "};
  height: ${(props) => props.h || " "};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.mleft || " "};
  margin-right: ${(props) => props.mr || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};
  justify-content: ${(props) => props.jtc || ""};
  align-items: ${(props) => props.alit || ""};
  background: ${(props) => props.bgck || ""};
  opacity: ${(props) => props.op || " "};
  @media (max-width: 800px) {
    display: ${(props) => props.dis || " "};
    height: ${(props) => props.h800 || "auto "};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};
    justify-content: ${(props) => props.jtcMob || ""};
    margin-right: ${(props) => props.mrMob || " "};
    padding: ${(props) => props.padMob || " "};
  }
  @media (max-width: 700px) {
    min-height: ${(props) => props.minh700 || "  "};
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.minh500 || "  "};
  }
`;

export const TextMain = styled.p`
  width: 460px;
  font-family: "eliotPro";
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 54px;
  color: #fbfbfb;
  margin: 0px 0px 32px 0px;
  @media (max-width: 800px) {
    width: 100%;
    text-align: start;
    font-size: 40px;
    line-height: 50px;
  }
`;

export const TextSub = styled.p`
width: 407px;
font-family: 'eliotPro';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 29px;
text-align: start;
color: #FBFBFB;
margin:0px 0px 32px 0px;
@media (max-width: 800px) {
  width: 100%;
 
  font-family: 'eliotPro';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: start;
  color: #fbfbfb;
  margin: 0px 0px 32px 0px;
  @media (max-width: 800px) {
    width: 100%;

    font-family: "FS Elliot Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #fbfbfb;
  }
`;

export const Text = styled.p`
  height: 52px;

  /* DESKTOP/Body text */

  /* DESKTOP/Body text */

  font-family: "eliotPro";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const TextOne = styled.p`
  width: 428px;
  font-family: "eliotPro";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  margin: 120px 0px 0px 80px;
`;

export const TextTwo = styled.p`
  font-family: "eliotPro";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  margin: 33px 0px 0px 80px;
`;
