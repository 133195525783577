import { useState } from "react";
import { TextTen } from "../../assets/StylesGeneric.styled";
import ArrowDown from "./../../assets/img/Arrow_down.svg";

const FeatureCard = ({
  bgColor = "#E3F5FD",
  title = "no title",
  description = "no description",
  image,
}) => {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <div
      style={{ backgroundColor: bgColor, borderRadius: "16px" }}
      className="text-center py-3"
    >
      <div className="mb-3">
        <img src={image} />
      </div>
      <TextTen color="#004C97" fontSize="20px" fontWeight="600">
        {title}
      </TextTen>
      <div className="d-md-block d-none  px-md-3 px-5 my-2 mt-3">
        {showDescription ? (
          ""
        ) : (
          <div>
            <img
              src={ArrowDown}
              className="pointer"
              onClick={() => setShowDescription(!showDescription)}
            />
          </div>
        )}
        {showDescription ? (
          <TextTen
            color="#333333"
            fontSize="16px"
            display="block"
            displayMob="none"
            textAlign="center"
          >
            {description}
          </TextTen>
        ) : (
          ""
        )}
      </div>
      <div className="d-md-none d-block px-5 my-2 mt-3">
        <TextTen
          color="#333333"
          display="none"
          displayMob="block"
          fontSize="16px"
          textAlign="center"
        >
          {description}
        </TextTen>
      </div>
    </div>
  );
};

export default FeatureCard;
