import styled from "styled-components";

export const ContainerGeneral = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fdir || "column"};
  width: ${(props) => props.w || "100%"};
  height: ${(props) => props.h || "800px"};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || "80px 0px 0px 0px "};
  align-items: center;
  /* justify-content:center ; */
  background: ${(props) => props.bgck || "#FFFFFF"};
  opacity: ${(props) => props.op || " "};
  @media (max-width: 800px) {
    height: ${(props) => props.h800 || "auto "};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};
    padding: ${(props) => props.padMob || "40px 0px 0px 0px "};
  }
  @media (max-width: 700px) {
    min-height: ${(props) => props.minh700 || "  "};
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.minh500 || "  "};
  }
`;

export const BoxContainer = styled.div`
  display: ${(props) => props.dis || "flex"};
  flex-direction: ${(props) => props.fdir || " "};
  width: ${(props) => props.w || " "};
  height: ${(props) => props.h || " "};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || ""};

  justify-content: center;
  background: ${(props) => props.bgck || "#fff"};
  opacity: ${(props) => props.op || " "};
  @media (max-width: 800px) {
    display: ${(props) => props.disMob || " "};
    height: ${(props) => props.h800 || "auto "};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};
  }
  @media (max-width: 700px) {
    min-height: ${(props) => props.minh700 || "  "};
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.minh500 || "  "};
  }
`;

export const BoxItem = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fdir || "column"};
  width: ${(props) => props.w || " "};
  height: ${(props) => props.h || " "};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};
  align-items: center;
  /* justify-content:center ; */
  background: ${(props) => props.bgck || "#fff"};
  opacity: ${(props) => props.op || " "};
  @media (max-width: 800px) {
    padding: ${(props) => props.padMob || " "};
    height: ${(props) => props.h800 || "auto "};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};
  }
  @media (max-width: 700px) {
    min-height: ${(props) => props.minh700 || "  "};
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.minh500 || "  "};
  }
`;

export const TextMain = styled.p`
  height: 48px;
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;

  height: 48px;
  font-family: "eliotPro";
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;

  display: flex;
  align-items: center;
  text-align: center;
  @media (max-width: 800px) {
    width: ${(props) => props.wMob || " 100% "};
    margin: ${(props) => props.wMob || " 0px 0px 50px 0px "};
  }
`;

export const TextSub = styled.p`
  width: 720px;
  font-family: "eliotPro";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  @media (max-width: 800px) {
    width: 90%;
    text-align: start;
  }
`;

export const Text = styled.p`
  height: 52px;

  /* DESKTOP/Body text */

  /* DESKTOP/Body text */

  font-family: "eliotPro";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const TextOne = styled.p`
  width: 347px;
  font-family: "eliotPro";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin: 0px;
  color: #003865;
  @media (max-width: 800px) {
    width: 100%;
    font-size: 20px;
  }
`;

export const TextTwo = styled.p`
width: 309px;
margin:16px 31px 0px 0px;
font-family: 'eliotPro';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
@media (max-width: 800px) {
  width: 100%;

  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #004c97;
`;

export const VermasBtn = styled.p`
  width: 100%;

  font-family: "eliotPro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #004c97;
`;
