import styled from "styled-components";

export const ContainerGeneral = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fdir || " "};
  width: ${(props) => props.w || "100%"};
  height: ${(props) => props.h || " "};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || "0px "};
  background: ${(props) => props.bgck || "#fff"};
  opacity: ${(props) => props.op || " "};
  @media (max-width: 800px) {
    height: ${(props) => props.h800 || "auto "};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};
  }
  @media (max-width: 700px) {
    min-height: ${(props) => props.minh700 || "  "};
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.minh500 || "  "};
  }
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fdir || " "};
  width: ${(props) => props.w || "100% "};
  height: ${(props) => props.h || " "};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};

  background: ${(props) => props.bgck || "#fff"};
  opacity: ${(props) => props.op || " "};
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    height: ${(props) => props.h800 || "auto "};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};
  }
  @media (max-width: 700px) {
    min-height: ${(props) => props.minh700 || "  "};
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.minh500 || "  "};
  }
`;

export const BoxItem = styled.div`
  display: ${(props) => props.dis || "flex"};
  flex-direction: ${(props) => props.fdir || " "};
  width: ${(props) => props.w || "50% "};
  height: ${(props) => props.h || " "};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};

  background: ${(props) => props.bgck || "#fff"};
  opacity: ${(props) => props.op || " "};
  @media (max-width: 800px) {
    display: ${(props) => props.disMob || " "};
    height: ${(props) => props.h800 || ""};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};
    padding: ${(props) => props.padMob || " "};
  }
`;

export const TextSub = styled.p`
  height: 52px;
  font-family: "eliotPro";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const TextOne = styled.p`
  width: 428px;
  font-family: "eliotPro";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  margin: 120px 0px 0px 80px;
  @media (max-width: 800px) {
    width: 100%;
    margin: 0px;
    text-align: start;
    font-family: "FS Elliot Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 39px;
    margin-top: 30px;
  }
`;

export const TextTwo = styled.p`
  font-family: "eliotPro";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  margin: 33px 0px 0px 80px;

  @media (max-width: 800px) {
    width: 363px;
    height: 26px;
    font-family: "eliotPro";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    margin: 33px 0px 0px 5px;
  }
`;

export const ImagenCar = styled.img`
  height: 463px;
`;

export const ImagenMobileCar = styled.img`
  width: 100%;
`;
