import React from "react";
import { HeaderContainer, LogoContainer } from "../../assets/Header.styled";
import LoGo from "./../../assets/img/Logo.svg";
import { Button } from "../../assets/StylesGeneric.styled";

const Header = (props) => {
  return (
    <>
      <HeaderContainer JsC="center">
        <LogoContainer m="15px 0px 0px 0px">
          <img src={LoGo} alt="Lgo" height="36px" width="127px" />
        </LogoContainer>

        <Button
          w="206px"
          h="30px"
          bgck="#FFFFFF"
          clr="#002E6D"
          mt="15px"
          DisMob="none"
        >
          {" "}
          ¡Quiero cambiarme!
        </Button>
      </HeaderContainer>
    </>
  );
};

export default Header;
