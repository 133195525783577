import React from "react";
import {
  ContainerGeneral,
  BoxContainer,
  BoxItem,
  TextMain,
  TextSub,
  TextOne,
  TextTwo,
  ImagenCel,
  ImagenAndroid,
  ImagenIso,
  ImagenIos,
} from "./../Elements/ContainerFive.styled";
import {
  ItemRow,
  ContainerButton,
  Btn,
} from "./../../../assets/StylesGeneric.styled";
import ImgIso from "./../../../assets/img/qrApple.svg";
import ImgAndroid from "./../../../assets/img/qrGoogle.svg";
import Celulares from "./../../../assets/img/Celulares.svg";
import Android from "./../../../assets/img/Android.svg";
import Ios from "./../../../assets/img/Iso.svg";

const ContainerFive = () => {
  const [isAndroid, setIsAndroid] = React.useState(true);
  return (
    <ContainerGeneral>
      <BoxContainer fdir="column" disMob="none">
        <div style={{ display: "flex" }}>
          <BoxItem w="50%" m="97px 0px 0px 0px" fdir="column" alite="end">
            <TextMain>Descarga la App gratis</TextMain>
            <TextOne>Escanea el código QR con tu celular</TextOne>
            <BoxItem m="0px 0px 0px 5%" fdir="column">
              <ContainerButton>
                <Btn
                  bg={isAndroid === true ? "#FFFFFF" : ""}
                  br={isAndroid === true ? "24px" : ""}
                  bs={
                    isAndroid === true
                      ? "0px 0px 12px rgba(0, 40, 85, 0.25)"
                      : ""
                  }
                  onClick={() => {
                    setIsAndroid(true);
                    // eventoGTM("Es_android");
                  }}
                >
                  Android
                </Btn>
                <Btn
                  bg={isAndroid === false ? "#FFFFFF" : ""}
                  br={isAndroid === false ? "24px" : ""}
                  bs={
                    isAndroid === false
                      ? "0px 0px 12px rgba(0, 40, 85, 0.25)"
                      : ""
                  }
                  onClick={() => {
                    setIsAndroid(false);
                    // eventoGTM("Es_ios");
                  }}
                >
                  IOS
                </Btn>
              </ContainerButton>

              {isAndroid === true && (
                <ItemRow>
                  <img
                    src={ImgAndroid}
                    alt="Principal App on GooglePlay"
                    className="Qr"
                  />
                </ItemRow>
              )}
              {isAndroid === false && (
                <ItemRow>
                  <img
                    src={ImgIso}
                    alt="Principal App on Playstore"
                    className="Qr"
                  />
                </ItemRow>
              )}
            </BoxItem>
          </BoxItem>

          <BoxItem w="50%" m="0px 0px 0px 0px" pstion="relative" tp="-58px">
            {" "}
            <img src={Celulares} alt="celulares" />
          </BoxItem>
        </div>
        <TextTwo>
          Estamos listos para acompañarte y ayudarte a planear tu futuro con
          nosotros
        </TextTwo>
      </BoxContainer>
      {/* Mob */}

      <BoxContainer fdir="column" disMob="flex" h800="600px" dis="none">
        <BoxItem
          w="100%"
          m="0px 0px 0px 0px"
          pstionMob="relative"
          tp800="-120px"
          tp425="-135px"
        >
          <ImagenCel src={Celulares} alt="celulares" />
        </BoxItem>

        <BoxItem
          alitemMob="center"
          fdirMob="column"
          w="100%"
          pstionMob="relative"
          tp800="-110px"
          tp375="-194px"
        >
          <TextMain>Descarga la App gratis</TextMain>
          <ImagenAndroid src={Android} alt="btnAndroid" />
          <ImagenIos src={Ios} alt="btnIso" />
          <TextSub>
            Estamos listos para acompañarte y ayudarte a planear tu futuro con
            nosotros
          </TextSub>
        </BoxItem>
      </BoxContainer>
    </ContainerGeneral>
  );
};

export default ContainerFive;
