import { useState } from "react";
import { TextTen } from "../../assets/StylesGeneric.styled";
import accordionArrow from "./../../assets/img/accordion_arrow.svg";

const Accordion = ({ seeMoreElement }) => {
  const [seeMore, setSeeMore] = useState(false);

  return (
    <>
      {seeMore ? (
        <>
          {seeMoreElement}
          <img src={accordionArrow} onClick={() => setSeeMore(false)} />
        </>
      ) : (
        <TextTen
          onClick={() => setSeeMore(true)}
          color="#004C97"
          textAlign="center"
          display="block"
          fontSize="20px"
          fontWeight="700"
        >
          Ver mas
        </TextTen>
      )}
    </>
  );
};

export default Accordion;
