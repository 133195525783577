import Carousel from "react-elastic-carousel";
import FeatureCard from "../../../../Common/Components/FeatureCard";
import Accordion from "../../../../Common/Components/Accordion";
import { TextTen } from "../../../../assets/StylesGeneric.styled";
import suiteCase from "../../../../assets/img/suit_case.svg";
import user from "../../../../assets/img/user.svg";
import badge from "../../../../assets/img/badge.svg";
import activeDot from "../../../../assets/img/activeDot.svg";
import inactiveDot from "../../../../assets/img/activeDot.png";

const features = [
  {
    feature: "Experiencia",
    img: user,
    description: "A nivel nacional para manejar tu Afore de la mejor manera",
  },
  {
    feature: "Compromiso",
    img: badge,
    description: "Con tu retiro para cumplir tus metas propuestas",
  },
  {
    feature: "Seguridad",
    img: suiteCase,
    description: "En tu Afore, puedes consultar el estatus, en todo momento",
  },
];

const Features = () => {
  return (
    <div className="d-flex row justify-content-center">
      {features.map((featureObj, idx) => {
        const { img, feature, description } = featureObj;
        return (
          <div key={idx} className="col-4">
            <FeatureCard
              image={img}
              title={feature}
              description={description}
            />
          </div>
        );
      })}
    </div>
  );
};

const PrincipalFeatures = () => {
  const FeaturesCarousel = () => (
    <>
      <TextTen
        display="block"
        margin="3rem 0px"
        textAlign="center"
        fontSize="20px"
      >
        Contamos con:
      </TextTen>
      <Carousel
        initialActiveIndex={0}
        showArrows={false}
        outerSpacing={0}
        renderPagination={({ pages, activePage, onClick }) => (
          <div
            className="mt-1 d-flex w-100 justify-content-between"
            style={{ position: "relative" }}
          >
            <div
              style={{
                borderTop: "0.5px solid black",
                position: "absolute",
                top: "33%",
                zIndex: "-1",
                width: "100%",
              }}
            />
            {pages.map((page) => {
              const isActivePage = activePage === page;
              const handlePage = () => {
                onClick(page);
              };
              return (
                <div className="my-4 pb-2 d-flex flex-column align-items-center">
                  <img
                    src={isActivePage ? activeDot : inactiveDot}
                    onClick={handlePage}
                    className="pointer"
                  />
                  <div>{page + 1}</div>
                </div>
              );
            })}
          </div>
        )}
      >
        {features.map((featureObj, idx) => {
          const { img, feature, description } = featureObj;
          return (
            <div key={idx}>
              <FeatureCard
                image={img}
                title={feature}
                description={description}
              />
            </div>
          );
        })}
      </Carousel>
    </>
  );

  return (
    <>
      <div className="d-md-none d-block px-5">
        <Accordion seeMoreElement={<FeaturesCarousel />} />
      </div>
      <div className="d-none d-md-block">
        <TextTen
          display="block"
          displayMob="none"
          textAlign="center"
          color="#333333"
          fontSize="24px"
          margin="3rem 0px"
        >
          Sabemos que tu futuro y el de tu familia son lo más importante para
          ti, para nosotros también.
          <br />
          Somos una Afore completa, contamos con:
        </TextTen>
        <Features />
        <TextTen
          display="block"
          displayMob="none"
          textAlign="center"
          color="#333333"
          fontSize="24px"
          marginTop="4rem"
        >
          Trabajemos juntos para construir una estrategia a tu medida
        </TextTen>
      </div>
    </>
  );
};

export default PrincipalFeatures;
