import React, { Fragment } from "react";
import SectionOne from "./Sections/SectionOne/SectionOne";
import Layout from "./Layout/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <BrowserRouter basename="/cambiate-a-principal-V2">
      <Layout>
        <Routes onUpdate={() => window.location.reload()}>
          <Route exact path="/" element={<SectionOne />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
