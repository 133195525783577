import styled from "styled-components";

export const ContainerGeneral = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fdir || " "};
  width: ${(props) => props.w || "100%"};
  height: ${(props) => props.h || "710px"};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || "0px "};
  background: ${(props) => props.bgck || "#fffF"};
  align-items: end;
  opacity: ${(props) => props.op || " "};
  @media (max-width: 800px) {
    flex-direction: ${(props) => props.fdirMob || ""};
    height: ${(props) => props.h800 || "750px "};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};
  }
  @media (max-width: 375px) {
    height: ${(props) => props.h375 || "850px "};
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.minh500 || "  "};
  }
`;

export const BoxContainer = styled.div`
  display: ${(props) => props.dis || " "};
  flex-direction: ${(props) => props.fdir || " "};
  width: ${(props) => props.w || "100% "};
  height: ${(props) => props.h || "667px "};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};
  align-items: center;
  background: linear-gradient(71.21deg, #004c97 29.17%, #002855 100%);

  opacity: ${(props) => props.op || " "};
  @media (max-width: 800px) {
    display: ${(props) => props.disMob || " "};
    height: ${(props) => props.h800 || " "};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};
  }
  @media (max-width: 700px) {
    min-height: ${(props) => props.minh700 || "  "};
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.minh500 || "  "};
  }
`;
export const BoxSubContainer = styled.div`
  display: flex;

  @media (max-width: 800px) {
    display: ${(props) => props.disMob || " "};
  }
  @media (max-width: 700px) {
  }
  @media (max-width: 500px) {
  }
`;
export const BoxItem = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fdir || " "};
  width: ${(props) => props.w || "50% "};
  height: ${(props) => props.h || " "};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};
  position: ${(props) => props.pstion || " "};
  top: ${(props) => props.tp || " "};
  background: ${(props) => props.bgck || ""};
  opacity: ${(props) => props.op || " "};
  align-items: ${(props) => props.alite || ""};
  @media (max-width: 800px) {
    flex-direction: ${(props) => props.fdirMob || " "};
    display: ${(props) => props.disMob || " "};
    position: ${(props) => props.pstionMob || " "};
    top: ${(props) => props.tp800 || " "};
    height: ${(props) => props.h800 || ""};
    min-height: ${(props) => props.mhM || " "};
    width: ${(props) => props.wMob || "100%"};
    background: ${(props) => props.bgckM || ""};

    align-items: ${(props) => props.alitemMob || ""};
  }
  @media (min-width: 425px) {
    position: ${(props) => props.pstionMob425 || " "};
    top: ${(props) => props.tp425 || " "};
  }
  @media (min-width: 375px) {
    position: ${(props) => props.pstionMob375 || " "};
    top: ${(props) => props.tp375 || " "};
  }
`;

export const ImagenCel = styled.img`
  @media (max-width: 800px) {
    width: ${(props) => props.w || "100% "};
    height: ${(props) => props.h || " "};
  }
`;

export const ImagenAndroid = styled.img`
  @media (max-width: 800px) {
    width: ${(props) => props.w || "135px "};
    height: ${(props) => props.h || "40px "};
    margin-top: 40px;
  }
`;

export const ImagenIos = styled.img`
  @media (max-width: 800px) {
    width: ${(props) => props.w || "135px "};
    height: ${(props) => props.h || " 45px"};
    margin-top: 25px;
  }
`;

export const TextMain = styled.p`
  display: ${(props) => props.disDesk || " "};
  margin: 0px 82px 16px 0px;
  font-family: "eliotPro";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;

  color: #fbfbfb;
  @media (max-width: 800px) {
    margin: 0px 0px 0px 0px;
  }

  color: #fbfbfb;
  @media (max-width: 800px) {
    margin: 0px 0px 0px 0px;
  }
`;

export const TextSub = styled.p`
  text-align: center;
  width: 100%;
  font-family: "eliotPro";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  color: #fbfbfb;
  margin-top: 40px;
`;

export const Text = styled.p`
  height: 52px;

  /* DESKTOP/Body text */

  font-family: "eliotPro";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const TextOne = styled.p`
  margin: 0px;
  margin-right: 10%;
  font-family: "eliotPro";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #fbfbfb;
  @media (max-width: 800px) {
    display: ${(props) => props.disMob || " "};
  }
`;

export const TextTwo = styled.p`
  text-align: center;
  width: 964px;
  font-family: "eliotPro";
  margin-left: 17%;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #fbfbfb;
  position: relative;
  top: -42px;
`;
