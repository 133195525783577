import React from "react";
import {
  ContainerGeneral,
  BoxContainer,
  BoxItem,
  TextMain,
  TextSub,
  TextOne,
  TextTwo,
} from "./../Elements/ContainerOne.styled.js";
import ImgOneSection from "./../../../assets/img/ImgContainerOneSO.png";
import { Button } from "./../../../assets/StylesGeneric.styled";
import { scroller } from "react-scroll";

const ContainerOne = (props) => {
  const scrollToElement = (element) => {
    scroller.scrollTo(element, {
      duration: 100,

      smooth: true,
      offset: 1,
    });
    props.onClose(false);
  };
  return (
    <ContainerGeneral bgck="red" h="600px">
      <BoxContainer fdir="row">
        <BoxItem
          bgck="#002855"
          jtc="end"
          alit="center"
          jtcMob="start"
          padMob="0px 4% 30px 4%"
        >
          <BoxItem fdir="column" mr="100px" mrMob="0px">
            <TextMain>Ahorro para tu retiro</TextMain>
            <TextSub>
              Conocer qué es una Afore es el primer paso para tomar el control
              sobre tu retiro.
            </TextSub>
            <Button
              w="206px"
              h="40px"
              bgMob="#FFFFFF"
              clrMob="#0061A0"
              wMob="100%"
              onClick={() => scrollToElement("InfoRetiro")}
            >
              Arma tu plan de retiro
            </Button>
          </BoxItem>
        </BoxItem>

        <BoxItem m="0px 0px 0px 0px" dis="none">
          <img src={ImgOneSection} width="100%" height="600px" />
        </BoxItem>
      </BoxContainer>
    </ContainerGeneral>
  );
};

export default ContainerOne;
